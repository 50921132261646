<template>
  <div>
    <div class="content__title content__title--party content__title--start">
      <h2 class="title">Прочее</h2>
      <router-link
        :to="{ name: 'profile-other-edit' }"
        class="link link--right"
      >
        <span>редактировать</span>
      </router-link>
    </div>
    <div class="data-cards">
      <router-link
        v-if="other.clothing_size === null && other.shoe_size === null && other.status === null && other.status_text === null"
        :to="{ name: 'profile-other-edit' }"
        class="data-cards__item data-cards__item--empty"
      >
        <div class="data-cards__title"><span>Доп. информация</span></div>
        <div class="data-cards__picture">
          <img src="@/assets/img/profile/other-def-icon.svg" alt="">
          <img src="@/assets/img/profile/other-color-icon.svg" alt="">
        </div>
        <div class="data-cards__text">
          На данный момент данные о прочей информации не заполнены, нажмите сюда, чтобы заполнить
        </div>
      </router-link>
      <div v-else class="data-cards__item">
        <div class="data-cards__title">
          <span>Доп. информация</span>
        </div>
        <div class="data-cards__picture">
          <img src="@/assets/img/profile/other-color-icon.svg" alt="">
        </div>
        <div class="data-cards__text"><b>Размер одежды:</b> {{ other.clothing_size ? other.clothing_size : '-' }}</div>
        <div class="data-cards__text"><b>Размер обуви:</b> {{ other.shoe_size ? other.shoe_size : '-' }}</div>
        <div class="data-cards__text"><b>Статус:</b> {{ other.status_text ? other.status_text : '-' }}</div>
        <div class="data-cards__text"><b>Семейное положение:</b> {{ other.marital ? 'женат / замужем' : 'холост / не замужем' }}</div>
        <div class="data-cards__text"><b>Участник Программы «Российская студенческая весна»:</b> {{ other.is_studvesna_member ? 'да' : 'нет' }}</div>
      </div>
      <div v-if="other.about" class="data-cards__item">
        <div class="data-cards__title">
          <span>О себе</span>
        </div>
        <div class="data-cards__picture">
          <img src="@/assets/img/profile/other-color-icon.svg" alt="">
        </div>
        <div class="data-cards__text"> {{ other.about }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProfileOther',
  data() {
    return {
      other: {}
    }
  },
  mounted() {
    this.$store.dispatch('profile/other/GET_DATA')
      .then(response => {
        this.other = response.data
      })
  },
}
</script>
